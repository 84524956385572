import { startOfWeek } from 'date-fns';
import { useEffect, useState } from 'react';
import cloudFunctionApi from '../../../../cloudfunctions';
import {
	AccountType,
	accountTypes,
	CompanyTypes,
	Site,
	UserProps,
} from '../../../../constants/Common';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { useFeatureFlagContext } from '../../../../providers/featureFlags/Provider';
import { formatSearchParamsDate } from '../../../helpers/dateFormatters';
import { sortObjectByField } from '../../../helpers/sortHelpers';
import { useTimesheetSearchParams } from '../../hooks/TimesheetNavHooks';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { Create, CreateFirebaseCalls } from './Create';
import {
	CreateDeprecated,
	CreateDeprecatedFirebaseCalls,
} from './CreateDeprecated';

type AvailableFirebaseCalls =
	| Extract<keyof FirebaseApi, CreateDeprecatedFirebaseCalls>
	| Extract<keyof FirebaseApi, CreateFirebaseCalls>
	| 'activeSitesByCompanyTypeSubscription';

export const CreateWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const {
		context: { loading, companies, sites, users, endDate, firebaseApi },
		dispatch,
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const featureFlags = useFeatureFlagContext();
	const createTimesheetsV3Enabled = featureFlags.get(
		'enableCreateTimesheetsV3',
	);
	const canChooseClientSites =
		userDetails.accountType === accountTypes.handler;
	const canActionTimesheets =
		userDetails.accountType === accountTypes.management ||
		userDetails.accountType === accountTypes.seniorManagement;

	const { searchParams, setTimesheetSearchParams } =
		useTimesheetSearchParams();

	const [allSites, setAllSites] = useState<Record<string, Site>>(sites);

	useEffect(() => {
		if (canChooseClientSites) {
			return firebaseApi.activeSitesByCompanyTypeSubscription(
				CompanyTypes.construction,
				(constructionSites) =>
					setAllSites(
						sortObjectByField(
							{
								...sites,
								...constructionSites,
							},
							'name',
						),
					),
			);
		} else {
			setAllSites(sites);
		}
	}, [canChooseClientSites, firebaseApi, sites]);

	const setSelected = (selectedID: string): void => {
		dispatch({ key: 'timesheetID', value: selectedID });
	};

	const navigateToTimesheet = (
		targetTimesheet: Pick<
			Timesheet,
			'id' | 'weekEnding' | 'timesheetStatus'
		>,
	): void => {
		const params = new URLSearchParams(searchParams);
		params.set(
			'endDate',
			formatSearchParamsDate(targetTimesheet.weekEnding.toDate()),
		);
		params.set('timesheetID', targetTimesheet.id);

		const page =
			targetTimesheet.timesheetStatus === TimesheetStatus.Active
				? 'overview'
				: canActionTimesheets
				? 'review'
				: 'details';

		// As we cannot change both timesheetID and weekEnding at the same time
		// navigate to page directly via the window interface
		const hrefDuplicateTimesheet = `/timesheets/${page}?${params}`;
		const openResult = window.open(hrefDuplicateTimesheet, '_blank');
		// if popup is blocked, redirect to the page
		if (openResult === null) {
			window.location.href = hrefDuplicateTimesheet;
		}
	};

	const setWeekQueryParams = (weekEnding: Date): void =>
		setTimesheetSearchParams({
			endDate: weekEnding,
			startDate: startOfWeek(weekEnding),
		});

	return (
		<>
			{createTimesheetsV3Enabled ? (
				<Create
					loading={loading}
					userDetails={userDetails}
					users={users}
					companies={companies}
					sites={allSites}
					weekEndingQueryParam={endDate}
					setWeekQueryParams={setWeekQueryParams}
					navigateToTimesheet={navigateToTimesheet}
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
				/>
			) : (
				<CreateDeprecated
					firebaseApi={firebaseApi}
					weekEnding={endDate}
					setWeekEnding={(value): void =>
						setTimesheetSearchParams({
							endDate: value,
							startDate: startOfWeek(value),
						})
					}
					userDetails={userDetails}
					users={users}
					setSelected={(value): void => setSelected(value)}
					loading={loading}
					canActionTimesheets={(
						[
							accountTypes.management,
							accountTypes.seniorManagement,
						] as AccountType[]
					).includes(userDetails.accountType)}
					navigateWithoutRouter={(href): void => {
						// As we cannot change both timesheetID and weekEnding at the same time
						// navigate to page directly via the window.location interface
						window.location.href = href;
					}}
				/>
			)}
		</>
	);
};
