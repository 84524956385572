import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatSearchParamsDate } from '../../helpers/dateFormatters';

enum TimesheetSearchParamKey {
	endDate = 'endDate',
	startDate = 'startDate',
	timesheetID = 'timesheetID',
}

type TimesheetSearchParams = {
	endDate?: Date;
	startDate?: Date;
	timesheetID?: string;
};

export const useTimesheetSearchParams = (): {
	searchParams: URLSearchParams;
	setTimesheetSearchParams: (params: TimesheetSearchParams) => void;
} => {
	const [searchParams, setSearchParams] = useSearchParams();

	const setSearchParamsCallback = useCallback(
		(params: TimesheetSearchParams): void => {
			setSearchParams((prev) => {
				if (params.startDate) {
					prev.set(
						TimesheetSearchParamKey.startDate,
						formatSearchParamsDate(params.startDate),
					);
				}
				if (params.endDate) {
					prev.set(
						TimesheetSearchParamKey.endDate,
						formatSearchParamsDate(params.endDate),
					);
				}
				if (params.timesheetID) {
					prev.set(
						TimesheetSearchParamKey.timesheetID,
						params.timesheetID,
					);
				}
				return prev;
			});
		},
		[setSearchParams],
	);

	return { searchParams, setTimesheetSearchParams: setSearchParamsCallback };
};
