import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';

export enum CreateEventType {
	DuplicateTimesheet = 'DuplicateTimesheet',
	Failed = 'Failed',
	Invalid = 'Invalid',
	NotesFailed = 'NotesFailed',
	Success = 'Success',
}

export type EventType =
	| {
			eventType: Exclude<
				CreateEventType,
				CreateEventType.Success | CreateEventType.NotesFailed
			>;
	  }
	| {
			eventType: CreateEventType.Success | CreateEventType.NotesFailed;
			timesheet: Timesheet;
	  };

export type CreateSnackbarProps = {
	event: EventType;
	navigateToTimesheet: (timesheet: Timesheet) => void;
	onClose: () => void;
};

export const CreateSnackbar = ({
	event,
	navigateToTimesheet,
	onClose,
}: CreateSnackbarProps): JSX.Element => {
	const [snackBarOpen, setSnackBarOpen] = useState(true);

	const handleSnackBarClose = (_?: unknown, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarOpen(false);
		onClose();
	};

	const ActionButton = ({
		buttonText,
		timesheet,
	}: {
		buttonText: string;
		timesheet: Timesheet;
	}): JSX.Element => (
		<>
			<Button
				size="small"
				color="inherit"
				variant="outlined"
				sx={{ marginRight: 0.5 }}
				onClick={(): void => {
					handleSnackBarClose();
					navigateToTimesheet(timesheet);
				}}>
				{buttonText}
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={(): void => handleSnackBarClose()}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	switch (event.eventType) {
		case CreateEventType.Success:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet created"
					autoHideDuration={10000}
					action={
						<ActionButton
							buttonText="Go To Timesheet"
							timesheet={event.timesheet}
						/>
					}
				/>
			);

		case CreateEventType.DuplicateTimesheet:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Duplicate timesheet found"
					autoHideDuration={2000}
					severity="info"
				/>
			);
		case CreateEventType.Failed:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Failed to create timesheet"
					severity="error"
				/>
			);
		case CreateEventType.NotesFailed:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet created without notes"
					autoHideDuration={10000}
					action={
						<ActionButton
							buttonText="Check Timesheet"
							timesheet={event.timesheet}
						/>
					}
					severity="warning"
				/>
			);
		case CreateEventType.Invalid:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Incomplete timesheet"
					autoHideDuration={2000}
					severity="error"
				/>
			);
	}
};
