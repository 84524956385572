import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
	Dispatch,
	SetStateAction,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { CloudFunctionApi } from '../../../../../cloudfunctions';
import {
	Activity,
	SiteLog,
	TimesheetActivity,
	UserDetails,
} from '../../../../../constants/Common';
import { NotesByDay } from '../../../../../constants/Note';
import { Timesheet } from '../../../../../constants/Timesheet/Timesheet';
import { validTimesheetDateRange } from '../../../../../constants/Timesheet/TimesheetUtilities';
import { Timestamp } from '../../../../../firebase/firebase';
import { FirebaseApi } from '../../../../../firebase/firebaseApi';
import { useAbortController } from '../../../../../hooks/useAbortController';
import { useUserAuthContext } from '../../../../../providers/UserProvider';
import DateWeekSelector from '../../../../DateWeekSelector/DateWeekSelector';
import { LoadingDots } from '../../../../Management/subcomponents/LoadingDots';
import { DuplicateTimesheetAlert } from '../../../DuplicateTimesheetAlert';
import { DetailsEventType, EventType } from '../DetailsSnackbar';
import {
	splitActivitiesByDay,
	splitSiteLogsByDay,
	validateTimesheet,
} from '../timesheetTableUtilities';
import {
	addActivity,
	deleteActivity,
	updateActivity,
	updateBreaks,
	updateNote,
	updateTimesheetErrors,
	updateWeek,
} from './actions';
import { DeleteTimesheetModal } from './DeleteTimesheetModal';
import {
	createInitialEditState,
	editTimesheetReducer,
	isDeletedActivity,
	isDeletedNote,
	isEditedNote,
	isNewActivity,
	isNewNote,
	isUpdatedActivity,
	updatedActivityConverter,
} from './reducer';
import { WeekContentsList } from './WeekContentsList';

export type EditTimesheetDialogFirebaseCalls =
	| 'getSite'
	| 'deleteTimesheet'
	| 'updateTimesheetAndActivities'
	| 'findDuplicateTimesheet';

export type EditTimesheetDialogCloudFunctionCalls =
	| 'deleteTimesheetNote'
	| 'createTimesheetNote'
	| 'editTimesheetNote';

export type EditTimesheetDialogProps = {
	userDetails: Pick<UserDetails, 'userID' | 'displayName'>;
	timesheet: Timesheet;
	activities: Record<string, Activity>;
	siteLogs: SiteLog[];
	dialogOpen: boolean;
	onClose: () => void;
	selectPreviousTimesheet: () => void;
	navigateToTimesheet: (timesheet: Timesheet) => void;
	setFeedbackSnackbarEvent: Dispatch<SetStateAction<EventType | null>>;
	firebaseApi: Pick<FirebaseApi, EditTimesheetDialogFirebaseCalls>;
	notes: NotesByDay;
	cloudFunctionApi: Pick<
		CloudFunctionApi,
		EditTimesheetDialogCloudFunctionCalls
	>;
};

export const EditTimesheetDialog = ({
	userDetails,
	timesheet,
	activities,
	siteLogs,
	dialogOpen,
	onClose,
	selectPreviousTimesheet,
	navigateToTimesheet,
	setFeedbackSnackbarEvent,
	firebaseApi,
	notes,
	cloudFunctionApi,
}: EditTimesheetDialogProps): JSX.Element => {
	const abortSignal = useAbortController();
	const user = useUserAuthContext();

	const [state, dispatch] = useReducer(
		editTimesheetReducer,
		{ userDetails, timesheet, activities, notes },
		createInitialEditState,
	);

	const [activitiesLoading, setActivitiesLoading] = useState(true);
	const [isMounted, setIsMounted] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
	const [weekDate, setWeekDate] = useState(timesheet.weekEnding.toDate());
	const [duplicateTimesheet, setDuplicateTimesheet] =
		useState<Timesheet | null>(null);
	const [siteActivities, setSiteActivities] = useState<TimesheetActivity[]>(
		[],
	);
	const preApprovedTimesheet = !!timesheet.preApproval;

	// To avoid other state updates after deleting last timesheet
	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	useEffect(() => {
		const fetchActivities = async (): Promise<void> => {
			setActivitiesLoading(true);
			const site = await firebaseApi.getSite(timesheet.site.id);

			setSiteActivities(
				site?.timesheetActivitiesV2.filter(
					(activity) => activity.active,
				) ?? [],
			);

			setActivitiesLoading(false);
		};

		fetchActivities();
	}, [firebaseApi, timesheet.site.id]);

	const handleUpdateWeek = async (newWeek: Date | null): Promise<void> => {
		const updatedWeek = newWeek ?? endOfWeek(new Date());
		setWeekDate(updatedWeek);
		const duplicateTimesheet = await firebaseApi.findDuplicateTimesheet(
			timesheet.employee.id,
			timesheet.contractedTo?.id ?? '',
			timesheet.site.id,
			// duplicate timesheet checks for the week starting on Monday
			Timestamp.fromDate(startOfWeek(updatedWeek)),
		);

		if (
			duplicateTimesheet === null ||
			duplicateTimesheet.id === timesheet.id
		) {
			dispatch(updateWeek(updatedWeek));
			setDuplicateTimesheet(null);
		} else {
			setDuplicateTimesheet(duplicateTimesheet);
		}
	};

	const saveTimesheet = async (): Promise<void> => {
		setSaving(true);
		const errors = validateTimesheet(state.updatedActivities);

		dispatch(updateTimesheetErrors(errors));

		if (Object.keys(errors).length === 0) {
			const weekChanged = !timesheet.week.isEqual(
				state.updatedTimesheet.week,
			);
			if (state.timesheetEdited) {
				const allActivities = Object.values(state.updatedActivities);
				const newActivities = allActivities
					.filter(isNewActivity)
					.map(updatedActivityConverter);
				const updatedActivities = allActivities
					.filter(isUpdatedActivity)
					.map(updatedActivityConverter);
				const deletedActivities = allActivities
					.filter(isDeletedActivity)
					.map((activity) => activity.id);

				await firebaseApi.updateTimesheetAndActivities(
					{
						timesheet: state.updatedTimesheet,
						lastEditor: {
							id: userDetails?.userID,
							name: userDetails?.displayName,
						},
					},
					newActivities,
					updatedActivities,
					deletedActivities,
				);
			}

			if (state.notesEdited && user) {
				const notesUpdates = Object.values(state.updatedNotes)
					.flatMap((day) => Object.values(day))
					.filter(
						(note) =>
							note.user.id === userDetails.userID &&
							(isNewNote(note) ||
								isDeletedNote(note) ||
								isEditedNote(note)),
					)
					.map((note) => {
						if (isNewNote(note)) {
							return cloudFunctionApi.createTimesheetNote(
								abortSignal,
								user,
								timesheet.id,
								note.day,
								note.note,
							);
						} else if (isDeletedNote(note)) {
							return cloudFunctionApi.deleteTimesheetNote(
								abortSignal,
								user,
								timesheet.id,
								note.id,
							);
						} else if (isEditedNote(note)) {
							return cloudFunctionApi.editTimesheetNote(
								abortSignal,
								user,
								timesheet.id,
								note.id,
								note.note,
							);
						} else {
							// make typescript happy
							return Promise.resolve();
						}
					});

				const noteUpdatesResults = await Promise.all(notesUpdates);
				// Check if all notes were updated successfully
				if (noteUpdatesResults.some((response) => response === false)) {
					setFeedbackSnackbarEvent({
						eventType: DetailsEventType.NotesFailed,
					});
					setSaving(false);
					onClose();
					return;
				}
			}

			// Set feedback snackbar event
			if (state.timesheetEdited && weekChanged) {
				selectPreviousTimesheet();
				setFeedbackSnackbarEvent({
					eventType: DetailsEventType.SaveWeekChanged,
					timesheet: state.updatedTimesheet,
				});
			} else if (state.timesheetEdited) {
				setFeedbackSnackbarEvent({
					eventType: DetailsEventType.Save,
				});
			} else if (state.notesEdited) {
				setFeedbackSnackbarEvent({
					eventType: DetailsEventType.Notes,
				});
			}

			onClose();
		} else {
			setFeedbackSnackbarEvent({
				eventType: DetailsEventType.Invalid,
			});
			setSaving(false);
		}
	};

	const deleteTimesheet = async (): Promise<void> => {
		if (!user) return;
		await firebaseApi.deleteTimesheet(abortSignal, user, timesheet.id);
		setFeedbackSnackbarEvent({ eventType: DetailsEventType.Delete });
		selectPreviousTimesheet();
		if (isMounted) {
			setDeleteConfirmOpen(false);
			onClose();
		}
	};

	const handleOnClose = (): void => {
		if (!saving) {
			onClose();
		}
	};

	return (
		<>
			<Dialog
				color="default"
				scroll="paper"
				fullWidth
				maxWidth="lg"
				open={dialogOpen}
				onClose={handleOnClose}
				PaperProps={{ style: { height: '100%' } }}>
				<DialogTitle sx={{ paddingLeft: 2 }}>
					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="center">
						<Grid item lg={8} sm={7} xs={12}>
							<Stack direction="row">
								<Tooltip title="Delete Timesheet">
									<span>
										<IconButton
											color="primary"
											disabled={saving}
											onClick={(): void => {
												setDeleteConfirmOpen(true);
											}}>
											<DeleteIcon fontSize="large" />
										</IconButton>
									</span>
								</Tooltip>
								<Typography
									variant="h3Bold"
									alignItems="center">
									Edit Timesheet
								</Typography>
							</Stack>
						</Grid>
						<Grid item lg={4} sm={5} xs={12}>
							<DateWeekSelector
								onChange={handleUpdateWeek}
								date={weekDate}
								allowFuture
								disabled={saving}
								textFieldProps={{ size: 'small' }}
								weekEnding
								dateLimits={validTimesheetDateRange(new Date())}
							/>
						</Grid>
						{duplicateTimesheet && (
							<Grid item xs={12}>
								<DuplicateTimesheetAlert
									handleGoToTimesheet={(): void => {
										navigateToTimesheet(duplicateTimesheet);
									}}
								/>
							</Grid>
						)}
					</Grid>
				</DialogTitle>
				<DialogContent>
					<Grid
						container
						spacing={1}
						justifyContent="space-between"
						alignItems="center">
						<Grid item>
							<Typography fontWeight="bold">Employee</Typography>
							<Typography fontSize="small">
								{timesheet.employee.name}
							</Typography>
						</Grid>
						<Grid item>
							<Typography fontWeight="bold">Site</Typography>
							<Typography fontSize="small">
								{timesheet.site.name}
							</Typography>
						</Grid>
						<Grid item>
							<Typography fontWeight="bold">Employer</Typography>
							<Typography fontSize="small">
								{timesheet.employer.name}
							</Typography>
						</Grid>
						{preApprovedTimesheet && (
							<Grid item>
								<Typography fontWeight="bold">
									{`Pre Approved ${timesheet.preApproval?.hours.total.billable} hrs`}
								</Typography>
								<Typography fontSize="small">
									{timesheet.preApproval?.reviewer.name}
								</Typography>
							</Grid>
						)}
					</Grid>

					{activitiesLoading ? (
						<LoadingDots
							style={{
								height: '100%',
								maxHeight: 'calc(100% - 64px)',
								alignItems: 'center',
							}}
						/>
					) : (
						<WeekContentsList
							disabled={saving}
							siteActivities={siteActivities}
							weekSiteLogHours={splitSiteLogsByDay(siteLogs)}
							weekActivities={splitActivitiesByDay(
								Object.values(state.updatedActivities).filter(
									(activity) => !isDeletedActivity(activity),
								),
							)}
							activityErrors={state.activityErrors}
							timesheetHours={state.updatedTimesheet.hours}
							timesheetId={state.updatedTimesheet.id}
							addActivity={(day): void =>
								dispatch(addActivity(day))
							}
							updateActivity={(day, activity, hours): void =>
								dispatch(updateActivity(day, activity, hours))
							}
							deleteActivity={(id): void =>
								dispatch(deleteActivity(id))
							}
							user={userDetails}
							notes={state.updatedNotes}
							updateBreaks={(day, hours): void =>
								dispatch(updateBreaks(day, hours))
							}
							updateNote={(day, id, note): void =>
								dispatch(updateNote(day, id, note))
							}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="flex-end" spacing={1}>
						{preApprovedTimesheet &&
							(state.timesheetEdited || state.notesEdited) && (
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="flex-end">
									<Typography fontSize="small" color="error">
										Changing Pre Approved Timesheet hours.
										Are you sure you want to continue?
									</Typography>
								</Grid>
							)}
						<Grid item>
							<Button
								variant="outlined"
								disabled={saving}
								onClick={onClose}>
								Cancel
							</Button>
						</Grid>
						<Grid item>
							<LoadingButton
								variant="contained"
								color="primary"
								disabled={
									(!state.timesheetEdited &&
										!state.notesEdited) ||
									duplicateTimesheet !== null ||
									saving
								}
								loading={saving}
								onClick={saveTimesheet}>
								{preApprovedTimesheet && state.timesheetEdited
									? 'Save Anyway'
									: 'Save'}
							</LoadingButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			<DeleteTimesheetModal
				deleteOpen={deleteConfirmOpen}
				deleteTimesheet={deleteTimesheet}
				onClose={(): void => setDeleteConfirmOpen(false)}
			/>
		</>
	);
};
