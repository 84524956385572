import EditIcon from '@mui/icons-material/Edit';
import {
	Divider,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { FirebaseApi } from '../../../../../firebase/firebaseApi';
import { PayrollIntegrationAtRest } from '../../../../../models/Integrations/PayrollIntegration';
import { hoursOptions } from '../../../../Timesheets/Timesheets/Details/timesheetTableUtilities';
import { LeavePayHoursDialog } from './LeavePayHoursDialog';

export type LeavePayHoursFooterProps = {
	loading: boolean;
	integration: Pick<
		PayrollIntegrationAtRest,
		'companyID' | 'leaveHoursPerDay'
	>;
	firebaseApi: Pick<FirebaseApi, 'updatePayrollLeaveHoursPerDay'>;
};

export const LeavePayHoursFooter = ({
	loading,
	integration,
	firebaseApi,
}: LeavePayHoursFooterProps): JSX.Element => {
	const [open, setOpen] = useState(false);

	const minHours = 1;
	const maxHours = 24;
	const minuteIncrement = 15;
	const options = hoursOptions(maxHours, minuteIncrement, minHours);

	const value = integration.leaveHoursPerDay || null;
	const textValue =
		integration.leaveHoursPerDay !== undefined
			? options[integration.leaveHoursPerDay] || null
			: null;

	const handleSave = async (value: number | null): Promise<void> => {
		await firebaseApi.updatePayrollLeaveHoursPerDay(integration.companyID, {
			leaveHoursPerDay: value ? value : undefined,
		});
		setOpen(false);
	};

	return (
		<Stack>
			<Divider />
			<Grid
				container
				alignItems="center"
				spacing={1}
				paddingX={2}
				paddingY={1}>
				<Grid item xs={6} container>
					<Typography>Leave Pay Hours</Typography>
				</Grid>
				<Grid item xs={6} container justifyContent="flex-end">
					<TextField
						size="small"
						InputLabelProps={{ shrink: true }}
						value={textValue || '8h 00m'}
						disabled
						inputProps={{
							sx: {
								textAlign: 'right',
								maxWidth: 64,
							},
						}}
					/>
					<IconButton
						onClick={(): void => setOpen(true)}
						color="primary"
						sx={{ marginX: 1 }}
						disabled={loading}>
						<EditIcon fontSize="small" />
					</IconButton>
				</Grid>
			</Grid>
			<LeavePayHoursDialog
				open={open}
				setOpen={setOpen}
				onSave={handleSave}
				options={options}
				leaveHoursPerDay={value}
			/>
		</Stack>
	);
};
