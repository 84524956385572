import { endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { useCallback, useEffect, useReducer } from 'react';
import { Outlet, useOutletContext } from 'react-router';
import cloudFunctionApi from '../../../cloudfunctions';
import { UserProps } from '../../../constants/Common';
import firebaseApi from '../../../firebase/firebaseApi';
import {
	calculateDateWeekRange,
	getWeekString,
	validateDateString,
} from '../../helpers/dateUtilities';
import { useTimesheetSearchParams } from '../hooks/TimesheetNavHooks';
import {
	TimesheetsContextFirebaseCalls,
	TimesheetsOutletContext,
} from './StateManagement/models';
import { createReducer } from './StateManagement/reducer';
import { Timesheets } from './Timesheets';
import { TimesheetsTableTheme } from './TimesheetsTableTheme';

export const TimesheetsPageWrapper = ({
	userDetails,
}: Required<UserProps>): JSX.Element => {
	const { searchParams, setTimesheetSearchParams } =
		useTimesheetSearchParams();

	const paramEndDateString = searchParams.get('endDate');
	const paramStartDateString = searchParams.get('startDate');
	const timesheetID = searchParams.get('timesheetID');

	const [context, dispatch] = useReducer(createReducer, {
		timesheetData: [],
		sites: {},
		companies: {},
		users: {},
		startDate: subWeeks(startOfWeek(new Date()), 1),
		endDate: subWeeks(endOfWeek(new Date()), 1),
		timesheetID: null,
		loading: true,
		firebaseApi,
	});

	useEffect(() => {
		const defaultStartDate = subWeeks(startOfWeek(new Date()), 1);
		const defaultEndDate = subWeeks(endOfWeek(new Date()), 1);

		const updateDateRange = (startDate: Date, endDate: Date): void => {
			dispatch({
				key: 'startDate',
				value: (prevStartDate: Date) => {
					const willUpdate =
						startDate.getTime() !== prevStartDate.getTime();
					return willUpdate ? startDate : prevStartDate;
				},
			});
			dispatch({
				key: 'endDate',
				value: (prevEndDate: Date) => {
					const willUpdate =
						endDate.getTime() !== prevEndDate.getTime();
					return willUpdate ? endDate : prevEndDate;
				},
			});
		};

		const validatedParamEndDate = validateDateString(paramEndDateString);
		const validatedParamStartDate =
			validateDateString(paramStartDateString);

		let startDate = defaultStartDate;
		let endDate = defaultEndDate;

		if (validatedParamEndDate) {
			const dateRange = calculateDateWeekRange(validatedParamEndDate);
			startDate = dateRange.startDate;
			endDate = dateRange.endDate;
		} else if (validatedParamStartDate) {
			const dateRange = calculateDateWeekRange(validatedParamStartDate);
			startDate = dateRange.startDate;
			endDate = dateRange.endDate;
		}

		updateDateRange(startDate, endDate);
		setTimesheetSearchParams({ startDate, endDate });
	}, [
		paramEndDateString,
		paramStartDateString,
		setTimesheetSearchParams,
		dispatch,
	]);

	useEffect(() => {
		dispatch({
			key: 'timesheetID',
			value: (prev) => {
				if (timesheetID) {
					return timesheetID;
				}
				return prev;
			},
		});
	}, [timesheetID]);

	return (
		<TimesheetsTableTheme>
			<>
				<Timesheets
					firebaseApi={context.firebaseApi}
					setTimesheetData={useCallback(
						(value) => dispatch({ key: 'timesheetData', value }),
						[],
					)}
					setSites={useCallback(
						(value) => dispatch({ key: 'sites', value }),
						[],
					)}
					setCompanies={useCallback(
						(value) => dispatch({ key: 'companies', value }),
						[],
					)}
					setUsers={useCallback(
						(value) => dispatch({ key: 'users', value }),
						[],
					)}
					users={context.users}
					weekEnding={context.endDate}
					weekString={getWeekString(context.endDate)}
					setLoading={useCallback(
						(value) => dispatch({ key: 'loading', value }),
						[],
					)}
					userDetails={userDetails}
					cloudFunctionApi={cloudFunctionApi}
				/>
				<Outlet context={{ context, dispatch }} />
			</>
		</TimesheetsTableTheme>
	);
};

/** Gets the reducer state and dispatch function required by children of Timesheets
 *
 * The optional Type parameter T is used to narrow the required functions of firebaseApi inside context
 */
export const useTimesheetsContext = <
	T extends keyof TimesheetsContextFirebaseCalls,
>(): TimesheetsOutletContext<T> =>
	useOutletContext<TimesheetsOutletContext<T>>();
