import { IntegrationLinkStatus } from '../../components/Integrations/Components/IntegrationStatusChip/IntegrationStatusChip';
import { RelevantLeaveType } from '../../constants/Leave';
import {
	ActivityTypesMapping,
	BaseIntegrationLink,
	IntegrationEmployee,
} from './IntegrationElements';
import { IntegrationStatus } from './IntegrationStatus';

// Shape in DB
export type PayrollIntegrationAtRest = {
	type: PayrollType;
	status: IntegrationStatus;
	companyID: string;
	leaveTypes: LeaveTypesMapping;
	activityTypes: ActivityTypesMapping;
	importedEmployees?: Record<string, BaseIntegrationLink>;
	overtimeThreshold?: number;
	leaveHoursPerDay?: number;
};

export const PayrollTypes = ['IPayroll', 'PayHero', 'Xero', 'Smartly'] as const;
export type PayrollType = (typeof PayrollTypes)[number];

export type LeaveTypesMapping = Record<RelevantLeaveType, BaseIntegrationLink>;

export const DEFAULT_ACTIVITY_KEY: keyof PayrollIntegrationAtRest['activityTypes'] =
	'Default';

export enum EmploymentType {
	Employee = 'Employee',
	Contractor = 'Contractor',
}
export type PayrollIntegrationEmployeeLink = BaseIntegrationLink & {
	employmentType: EmploymentType;
};

export type PayrollIntegrationEmployee = IntegrationEmployee & {
	employmentType: EmploymentType;
};

export const getLinkStatus = (
	link: PayrollIntegrationEmployee | null,
	integrationEmployee: PayrollIntegrationEmployee | null,
): IntegrationLinkStatus => {
	if (link === null) {
		return IntegrationLinkStatus.Unlinked;
	} else if (integrationEmployee === null) {
		return IntegrationLinkStatus.Missing;
	} else {
		return IntegrationLinkStatus.Linked;
	}
};

export type TempEmployeeLink =
	| PayrollIntegrationEmployee
	| Pick<PayrollIntegrationEmployee, 'id'>;

export type EmployeeIntegrationTableRow = {
	id: PayrollIntegrationEmployeeLink['id'];
	name: PayrollIntegrationEmployeeLink['name'];
	status: IntegrationLinkStatus;
	account: TempEmployeeLink;
};

export const isPayrollEmployeeLink = (
	value: TempEmployeeLink,
): value is PayrollIntegrationEmployeeLink =>
	(value as PayrollIntegrationEmployeeLink).name !== undefined;
