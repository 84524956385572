import { Site } from '../constants/Common';
import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestDeprecated,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type CreateSiteRequestBody = {
	site: Pick<
		Site,
		| 'name'
		| 'jobNumber'
		| 'siteContact'
		| 'siteContactNumber'
		| 'address'
		| 'city'
		| 'region'
		| 'autoSignOutTime'
		| 'showCovidWarning'
		| 'allowAppSignIn'
		| 'startTime'
		| 'hasInductions'
		| 'safetyCourseRequiredForInduction'
		| 'companyID'
		| 'createdBy'
		| 'createdByID'
		| 'status'
	>;
};

type CreateSiteResponseBody = { message: string };

const createSite = async (
	abortSignal: AbortSignal,
	user: User,
	newSite: CreateSiteRequestBody,
): Promise<string | undefined> => {
	const response = await makeCloudFunctionRequestDeprecated<
		CreateSiteResponseBody,
		CreateSiteRequestBody
	>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.CreateSite,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: newSite,
	});

	return response?.message;
};

type UpdateSiteRequestBody = {
	id: string;
	site: Partial<CreateSiteRequestBody['site']>;
};

const updateSite = async (
	abortSignal: AbortSignal,
	user: User,
	siteID: string,
	updatedSite: UpdateSiteRequestBody['site'],
): Promise<boolean | undefined> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<UpdateSiteRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.UpdateSite,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					id: siteID,
					site: updatedSite,
				},
			},
		);

	return response;
};

type ArchiveSiteRequestBody = {
	siteID: string;
};

const archiveSite = async (
	abortSignal: AbortSignal,
	user: User,
	siteID: string,
): Promise<boolean> => {
	const response =
		await makeCloudFunctionRequestReturnSuccessStatus<ArchiveSiteRequestBody>(
			{
				abortSignal,
				user,
				url: HTTPSCloudFunctionURLs.ArchiveSite,
				method: 'POST',
				services: defaultCloudFunctionServices,
				body: {
					siteID: siteID,
				},
			},
		);

	return response;
};

export const sitesFirestore = { createSite, updateSite, archiveSite };
