import { Visibility, VisibilityOff } from '@mui/icons-material';
import Cancel from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
	TimesheetActivity,
	UserDetails,
	emptyFunction,
} from '../../../../constants/Common';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import {
	ActivityLink,
	ProjectTrackingType,
} from '../../../../models/Integrations/ProjectTrackingIntegration';
import { getUuid } from '../../../helpers/uuidHelpers';
import { SiteActivitiesWarningAlert } from './SiteActivitiesWarningAlert';

export type SiteActivitiesFirebaseCalls =
	| 'siteSubscription'
	| 'updateSiteActivities'
	| 'projectTrackingIntegrationActivityLinksSubscription'
	| 'getProjectTrackingIntegration';

export type SiteActivitiesProps = {
	userDetails: UserDetails;
	siteActivities: TimesheetActivity[];
	firebaseApi: Pick<FirebaseApi, SiteActivitiesFirebaseCalls>;
};

export const SiteActivities = ({
	userDetails,
	siteActivities,
	firebaseApi,
}: SiteActivitiesProps): JSX.Element => {
	const navigate = useNavigate();

	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [newActivityName, setNewActivityName] = useState<string>('');
	const [editActivity, setEditActivity] = useState<TimesheetActivity>({
		activityName: '',
		id: '',
		active: true,
	});
	const [errorMap, setErrorMap] = useState<Record<string, string>>({
		newActivityName: '',
		editActivityName: '',
	});

	const [projectTrackingIntegrationType, setProjectTrackingIntegrationType] =
		useState<ProjectTrackingType | null>(null);
	const [projectTrackingActivityLinks, setProjectTrackingActivityLinks] =
		useState<Record<string, ActivityLink>>({});
	const [
		projectTrackingActivityLinksLoaded,
		setProjectTrackingActivityLinksLoaded,
	] = useState(false);

	const showUnlinkedActivitesAlert =
		projectTrackingIntegrationType !== null &&
		projectTrackingActivityLinksLoaded &&
		siteActivities.some(
			(siteActivity) =>
				projectTrackingActivityLinks[siteActivity.id] === undefined,
		);

	useEffect(() => {
		if (newActivityName !== '')
			setErrorMap((prevState) => ({ ...prevState, newActivityName: '' }));
	}, [newActivityName]);

	useEffect(() => {
		if (editActivity.activityName !== '')
			setErrorMap((prevState) => ({
				...prevState,
				editActivityName: '',
			}));
	}, [editActivity.activityName]);

	useEffect(() => {
		const fetchIntegration = async (): Promise<void> => {
			const integration = await firebaseApi.getProjectTrackingIntegration(
				userDetails.companyID,
			);

			setProjectTrackingIntegrationType(integration?.type ?? null);
		};
		fetchIntegration();
	}, [firebaseApi, userDetails.companyID]);

	useEffect(() => {
		let unsub = emptyFunction;
		if (
			projectTrackingIntegrationType &&
			userDetails.siteID &&
			userDetails.siteID !== ''
		) {
			unsub =
				firebaseApi.projectTrackingIntegrationActivityLinksSubscription(
					userDetails.companyID,
					userDetails.siteID,
					(links) => {
						setProjectTrackingActivityLinks(links);
						setProjectTrackingActivityLinksLoaded(true);
					},
				);
		}
		return unsub;
	}, [
		projectTrackingIntegrationType,
		firebaseApi,
		userDetails.companyID,
		userDetails.siteID,
	]);

	const addActivity = async (): Promise<void> => {
		const newActivityID = getUuid();
		if (
			siteActivities.some(
				(activity) => activity.activityName === newActivityName,
			)
		) {
			setErrorMap((prevState) => ({
				...prevState,
				newActivityName: 'Activity Aready Exists',
			}));
			return;
		}

		const trimmedName = newActivityName.trim();

		if (trimmedName === '') {
			setErrorMap((prevState) => ({
				...prevState,
				newActivityName: 'Activity name cannot be blank',
			}));
			return;
		}

		const activity = {
			activityName: trimmedName,
			id: newActivityID,
			active: true,
		};
		await firebaseApi.updateSiteActivities(userDetails.siteID, {
			timesheetActivitiesV2: [...siteActivities, activity],
		});
		setNewActivityName('');
	};

	const deleteActivity = async (id: string): Promise<void> => {
		const newActivities = siteActivities.filter(
			(activity) => activity.id !== id,
		);
		await firebaseApi.updateSiteActivities(userDetails.siteID, {
			timesheetActivitiesV2: newActivities,
		});
	};

	const openEditActivityDialogue = (activity: TimesheetActivity): void => {
		setEditActivity(activity);
		setDialogOpen(true);
	};

	const handleEditActivitySave = async (): Promise<void> => {
		const trimmedName = editActivity.activityName.trim();

		if (trimmedName === '') {
			setErrorMap((prevState) => ({
				...prevState,
				editActivityName: 'Activity name cannot be blank',
			}));
		}

		editActivity.activityName = trimmedName;

		const timesheetActivitiesV2 = [
			...siteActivities.filter(
				(activity) => activity.id !== editActivity.id,
			),
			editActivity,
		];
		await firebaseApi.updateSiteActivities(userDetails.siteID, {
			timesheetActivitiesV2: timesheetActivitiesV2,
		});

		setDialogOpen(false);
	};

	const handleToggleActivityActive = (
		activityToUpdate: TimesheetActivity,
	): void => {
		const newActivites = siteActivities.filter(
			(activity) => activity.id !== activityToUpdate.id,
		);
		activityToUpdate.active = !activityToUpdate.active;
		firebaseApi.updateSiteActivities(userDetails.siteID, {
			timesheetActivitiesV2: [...newActivites, activityToUpdate],
		});
	};

	const renderAddActivity = (): ReactElement => (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{userDetails.siteID === '' ? (
					<Typography variant="h6">
						Select A Site To Add Activities
					</Typography>
				) : (
					<Typography variant="h6">Add Activity</Typography>
				)}
			</Grid>
			<Grid item xs={6}>
				<TextField
					label="New Activity Name"
					variant="outlined"
					fullWidth
					disabled={userDetails.siteID === ''}
					value={newActivityName}
					onChange={(event): void => {
						setNewActivityName(event.target.value);
					}}
					error={errorMap.newActivityName !== ''}
					helperText={errorMap.newActivityName}
				/>
			</Grid>
			<Grid item xs={6}>
				<Button
					onClick={(): void => {
						addActivity();
					}}
					disabled={userDetails.siteID === ''}
					variant="outlined"
					fullWidth
					sx={{ height: '56px' }}>
					Add
				</Button>
			</Grid>
		</Grid>
	);

	const renderSiteActivity = (
		activity: TimesheetActivity,
		canDelete: boolean,
	): JSX.Element => {
		return (
			<Paper elevation={2}>
				<ListItem
					secondaryAction={
						<>
							<Tooltip title="Visible to Staff">
								<IconButton
									onClick={(): void =>
										handleToggleActivityActive(activity)
									}>
									{activity.active ? (
										<Visibility color="primary" />
									) : (
										<VisibilityOff />
									)}
								</IconButton>
							</Tooltip>
							<Tooltip title="Edit">
								<IconButton
									onClick={(): void =>
										openEditActivityDialogue(activity)
									}>
									<EditIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete">
								<>
									<IconButton
										data-testid="delete-activity"
										onClick={async (): Promise<void> =>
											await deleteActivity(activity.id)
										}
										disabled={!canDelete}>
										<DeleteIcon />
									</IconButton>
								</>
							</Tooltip>
						</>
					}>
					<ListItemText
						primary={activity.activityName}
						primaryTypographyProps={{
							style: {
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								paddingRight: '0 80px 0 0',
							},
						}}
					/>
				</ListItem>
			</Paper>
		);
	};

	const renderActivityList = (): ReactElement => {
		return (
			<Stack spacing={1}>
				{userDetails.siteID !== '' && (
					<>
						<Typography variant="h6">Site Activities</Typography>
						<Box>
							<Grid container spacing={2}>
								{siteActivities
									.sort((siteActivityA, siteActivityB) =>
										siteActivityA.activityName.localeCompare(
											siteActivityB.activityName,
										),
									)
									.map((activity: TimesheetActivity) => (
										<Grid item xs={6} key={activity.id}>
											{renderSiteActivity(
												activity,
												siteActivities.length > 1,
											)}
										</Grid>
									))}
							</Grid>
						</Box>
					</>
				)}
			</Stack>
		);
	};

	const renderDialog = (): JSX.Element => (
		<Dialog
			open={dialogOpen}
			onClose={(): void => setDialogOpen(false)}
			fullWidth={true}>
			<DialogTitle>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between">
					<Typography variant="h6">Edit Activity</Typography>
					<IconButton onClick={(): void => setDialogOpen(false)}>
						<Cancel />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: 2,
				}}>
				<Stack
					spacing={2}
					sx={{
						paddingTop: 1,
						width: '100%',
					}}>
					<TextField
						label="Activity Name"
						variant="outlined"
						value={editActivity.activityName}
						onChange={(event): void =>
							setEditActivity({
								...editActivity,
								activityName: event.target.value,
							})
						}
						error={errorMap.editActivityName !== ''}
						helperText={errorMap.editActivityName}
					/>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ padding: 2, paddingTop: 0 }}>
				<Button
					onClick={handleEditActivitySave}
					variant="contained"
					fullWidth>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderUnlinkedActivitiesAlert = (): JSX.Element => (
		<Box pb={2}>
			<SiteActivitiesWarningAlert
				navigate={navigate}
				integrationType={projectTrackingIntegrationType}
			/>
		</Box>
	);

	return (
		<>
			{showUnlinkedActivitesAlert && renderUnlinkedActivitiesAlert()}
			<Card variant="outlined">
				<CardContent>
					<Stack
						spacing={2}
						divider={<Divider orientation="horizontal" flexItem />}>
						{renderAddActivity()}
						{renderActivityList()}
					</Stack>
					{renderDialog()}
				</CardContent>
			</Card>
		</>
	);
};
