import { Autocomplete, MenuItem, TextField } from '@mui/material';

export type LeavePayHoursDropdownProps = {
	hours: number | null;
	setHours: (hours: number | null) => void;
	options: Record<number, string>;
	disabled?: boolean;
};

export const LeavePayHoursDropdown = ({
	hours,
	setHours,
	options,
	disabled,
}: LeavePayHoursDropdownProps): JSX.Element => {
	return (
		<Autocomplete
			sx={{ minWidth: '160px', paddingLeft: 1 }}
			disabled={disabled}
			options={Object.keys(options)
				.map(parseFloat)
				.sort((hours1, hours2): number => (hours1 > hours2 ? 1 : -1))}
			onChange={(_, option): void => {
				setHours(option);
			}}
			renderInput={(props): JSX.Element => (
				<TextField
					{...props}
					label="Hours"
					size="small"
					placeholder="8h 00m"
					InputLabelProps={{ shrink: true }}
					inputProps={{
						...props.inputProps,
					}}
				/>
			)}
			value={hours}
			size="small"
			getOptionLabel={(option): string => options[option]}
			renderOption={(props, option): JSX.Element => (
				<MenuItem {...props} key={option} value={option}>
					{options[option]}
				</MenuItem>
			)}
		/>
	);
};
