import { isEmpty } from 'lodash';
import { WorkerAttendance } from '../../constants/Attendance';
import {
	AccountType,
	UserDetails,
	WorkerType,
	accountTypes,
	emptyFunction,
} from '../../constants/Common';
import { FieldValue } from '../../firebase/firebase';
import firebaseApi from '../../firebase/firebaseApi';
import { RemoveFrom } from './RemoveFrom';

export const signInBookAccountType = [
	accountTypes.handler,
	accountTypes.management,
	accountTypes.seniorManagement,
] as const;

export type SignInBookAccountType = Extract<
	AccountType,
	(typeof signInBookAccountType)[number]
>;

type SignInBookConfig = {
	workerUsersSubscription: (
		callback: (workers: Record<string, UserDetails>) => Promise<void>,
	) => () => void;
	removedFrom: RemoveFrom;
	removeWorkers: (workers: WorkerAttendance[]) => Promise<void>;
	titles: string[];
};

export const signInBookConfig = (
	accountType: SignInBookAccountType,
	userSiteAndCompanyID: Pick<
		UserDetails,
		'siteID' | 'siteCompanyID' | 'companyID'
	>,
): SignInBookConfig => {
	const isMainContractor =
		userSiteAndCompanyID.companyID === userSiteAndCompanyID.siteCompanyID;
	switch (accountType) {
		case 'management': {
			const config: SignInBookConfig = {
				workerUsersSubscription: (callback) =>
					firebaseApi.subscribeWorkerUsersByContractedSite(
						userSiteAndCompanyID.companyID,
						userSiteAndCompanyID.siteID,
						callback,
					),
				removedFrom: RemoveFrom.Site,
				removeWorkers: async (workers) => {
					const workerUpdates: Parameters<
						typeof firebaseApi.updateUserDetailsSiteInfoBatch
					>[0] = workers.map((worker) => {
						return {
							userID: worker.userID,
							site: '',
							siteID: '',
							siteCompany: '',
							siteCompanyID: '',
						};
					});

					await firebaseApi.updateUserDetailsSiteInfoBatch(
						workerUpdates,
					);
				},
				titles: ['attendance', 'sign in book', 'guests'],
			};

			if (userSiteAndCompanyID.siteID === '') {
				config.workerUsersSubscription = (callback): (() => void) => {
					callback({});
					return emptyFunction;
				};
			} else if (isMainContractor) {
				config.workerUsersSubscription = (callback): (() => void) =>
					firebaseApi.subscribeWorkerUsersBySite(
						userSiteAndCompanyID.siteID,
						callback,
					);
			}
			return config;
		}
		case 'handler':
			return {
				workerUsersSubscription: (callback) =>
					firebaseApi.subscribeWorkerUsersByCompany(
						userSiteAndCompanyID.companyID,
						callback,
					),
				removedFrom: RemoveFrom.Company,
				removeWorkers: async (workers): Promise<void> => {
					const userProfileUpdates: Promise<void>[] = [];
					const workerUpdates: Parameters<
						typeof firebaseApi.updateUserDetailsCompanyInfoBatch
					>[0] = workers.map((worker) => {
						userProfileUpdates.push(
							firebaseApi.updateUserProfileCompany(
								worker.userID,
								{
									company: {
										id: '',
										name: '',
									},
								},
							),
						);

						return {
							userID: worker.userID,
							accountType: '',
							workerType:
								FieldValue.delete() as unknown as WorkerType, // delete field
							site: '',
							siteID: '',
							siteCompany: '',
							siteCompanyID: '',
							company: '',
							companyID: '',
							contractedTo: null,
						};
					});

					await firebaseApi.updateUserDetailsCompanyInfoBatch(
						workerUpdates,
					);

					await Promise.all(userProfileUpdates);
				},
				titles: ['attendance', 'sign in book'],
			};
		case 'seniorManagement':
			return {
				workerUsersSubscription: (callback): (() => void) => {
					let workersOnCompanySites = {};
					let loadingWorkersOnCompanySites = true;
					let workersContractedToCompanyNotOnCompanySites = {};
					let loadingWorkersContractedToCompanyNotOnCompanySites =
						true;

					const workersOnCompanyOwnedSitesCallback = async (
						workers: Record<string, UserDetails>,
					): Promise<void> => {
						loadingWorkersOnCompanySites = false;
						if (
							isEmpty(workersOnCompanySites) &&
							isEmpty(workers) &&
							loadingWorkersContractedToCompanyNotOnCompanySites
						)
							return; // reduce number of callback calls if prev and new are empty

						workersOnCompanySites = workers;
						await callback({
							...workersContractedToCompanyNotOnCompanySites,
							...workersOnCompanySites,
						});
					};

					const contractedWorkersNotOnCompanyOwnedSitesCallback =
						async (
							workers: Record<string, UserDetails>,
						): Promise<void> => {
							loadingWorkersContractedToCompanyNotOnCompanySites =
								false;
							const filteredWorkers = Object.fromEntries(
								Object.entries(workers).filter(
									([_, worker]) =>
										worker.siteCompanyID !== '' &&
										worker.siteCompanyID !==
											userSiteAndCompanyID.companyID,
								),
							);
							if (
								isEmpty(
									workersContractedToCompanyNotOnCompanySites,
								) &&
								isEmpty(filteredWorkers) &&
								loadingWorkersOnCompanySites
							)
								return; // reduce number of callback calls if prev and new are empty

							workersContractedToCompanyNotOnCompanySites =
								filteredWorkers;
							await callback({
								...workersOnCompanySites,
								...workersContractedToCompanyNotOnCompanySites,
							});
						};

					const unsub1 =
						firebaseApi.subscribeWorkerUsersBySiteCompany(
							userSiteAndCompanyID.companyID,
							workersOnCompanyOwnedSitesCallback,
						);
					const unsub2 = firebaseApi.subscribeWorkerUsersByContracted(
						userSiteAndCompanyID.companyID,
						contractedWorkersNotOnCompanyOwnedSitesCallback,
					);
					return () => {
						unsub1();
						unsub2();
					};
				},
				removedFrom: RemoveFrom.Site,
				removeWorkers: async (workers): Promise<void> => {
					const workerUpdates: Parameters<
						typeof firebaseApi.updateUserDetailsSiteInfoBatch
					>[0] = workers.map((worker) => {
						return {
							userID: worker.userID,
							site: '',
							siteID: '',
							siteCompany: '',
							siteCompanyID: '',
						};
					});

					await firebaseApi.updateUserDetailsSiteInfoBatch(
						workerUpdates,
					);
				},
				titles: ['attendance', 'sign in book', 'guests'],
			};
	}
};
