// Realised there was way too much logic within the component.
// So we get this collection of functions to unit test :yay:
import { intervalToDuration } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { cloneDeep } from 'lodash';
import {
	Activity,
	DayString,
	dayStrings,
	SiteLogType,
} from '../../../../constants/Common';
import { NotesByDay, TimesheetNote } from '../../../../constants/Note';
import { getTimeForHours } from '../../../../constants/stringUtilities';
import {
	TimesheetSiteLog,
	TimesheetTableRowTimesheet,
	TimesheetTableActivity,
	ActivityEditFields,
} from '../../../../constants/Timesheet/Timesheet';
import { formatTimeZone } from '../../../helpers/dateFormatters';
import { getDayString } from '../../../helpers/dateUtilities';
import { sortByTimestampField } from '../../../helpers/sortHelpers';
import { EditState, isDeletedActivity } from './EditTimesheet/reducer';

const createWeekWithDefault = <T>(defaultValue: T): Record<DayString, T> =>
	dayStrings.reduce((prev, day) => {
		prev[day] = cloneDeep(defaultValue);
		return prev;
	}, {} as Record<DayString, T>);

export const createWeekOrAllWithDefault = <T>(
	defaultValue: T,
): Record<TimesheetNote['day'], T> =>
	[...dayStrings, 'All'].reduce((prev, day) => {
		prev[day as TimesheetNote['day']] = cloneDeep(defaultValue);
		return prev;
	}, {} as Record<TimesheetNote['day'], T>);

export const splitActivitiesByDay = <
	A extends Pick<EditState['updatedActivities'][string], ActivityEditFields>,
>(
	activities: A[],
): Record<DayString, Pick<Activity, ActivityEditFields>[]> =>
	activities.reduce<Record<DayString, Pick<Activity, ActivityEditFields>[]>>(
		(activitiesByDay, activity) => {
			activitiesByDay[activity.day].push({
				id: activity.id,
				activity: activity.activity ?? { name: '', id: '' },
				hours: activity.hours,
				day: activity.day,
			});
			return activitiesByDay;
		},
		createWeekWithDefault([]),
	);

export const splitNotesByDay = (notes: TimesheetNote[]): NotesByDay =>
	notes.reduce<NotesByDay>(
		(notesByDay, note) => {
			notesByDay[note.day].push(note);
			return notesByDay;
		},
		{ ...createWeekWithDefault([]), All: [] },
	);

/** get the earliest 'in' log or the latest 'out' log for a day */
const getRelevantLog = (
	sitelog: TimesheetSiteLog,
	existingLog: TimesheetSiteLog | null,
): TimesheetSiteLog => {
	if (!existingLog) {
		return sitelog;
	}
	const isSitelogEarlier =
		sitelog.datetime.toMillis() < existingLog.datetime.toMillis();

	if (sitelog.type === SiteLogType.In && isSitelogEarlier) {
		return sitelog;
	} else if (sitelog.type === SiteLogType.In && !isSitelogEarlier) {
		return existingLog;
	} else if (sitelog.type === SiteLogType.Out && isSitelogEarlier) {
		return existingLog;
	} else {
		return sitelog;
	}
};

const formatInterval = (start: Date, end: Date): string => {
	const duration = intervalToDuration({
		start,
		end,
	});
	const hours = (duration.hours ?? 0) + (duration.minutes ?? 0) / 60;
	return getTimeForHours(hours);
};

// This function needs work to account for split shifts over midnight
// At the moment we only display duration if it is a correct site log pair
export const formatSiteLogs = (inLog?: Date, outLog?: Date): string | null => {
	let formattedLogsText = null;
	if (!inLog) return null;
	// If there's an inLog but no outLog or outLog is before inLog
	// We do not try match the pair
	else if (!outLog || (outLog && inLog && outLog < inLog)) {
		const inLogText = formatTimeZone(inLog);
		formattedLogsText = `${inLogText} - ???`;
	}
	// A normal shift pair
	else if (inLog && outLog && inLog < outLog) {
		const inLogText = formatTimeZone(inLog);
		const outLogText = formatTimeZone(outLog);
		const duration = formatInterval(inLog, outLog);
		formattedLogsText = `${inLogText} - ${outLogText} ${duration}`;
	}

	return formattedLogsText;
};

export const splitSiteLogsByDay = (
	siteLogs: TimesheetSiteLog[],
): Record<DayString, TimesheetTableRowTimesheet['siteLogs']> => {
	const sortedSiteLogs = sortByTimestampField(siteLogs, 'datetime');

	return sortedSiteLogs.reduce<
		Record<DayString, TimesheetTableRowTimesheet['siteLogs']>
	>(
		(prev, sitelog) => {
			const day = getDayString(
				utcToZonedTime(
					sitelog.datetime.toDate(),
					'Pacific/Auckland',
				).getDay() as Day,
			);

			const dayLogs = prev[day] || {
				[SiteLogType.In]: null,
				[SiteLogType.Out]: null,
				formattedLogs: null,
			};

			const relevantLog = getRelevantLog(sitelog, dayLogs[sitelog.type]);

			if (relevantLog.type === SiteLogType.Out) {
				// Check if there's an earlier In log for this day
				const inLog = dayLogs[SiteLogType.In]?.datetime?.toDate();

				if (!inLog || relevantLog.datetime.toDate() < inLog) {
					// Skip this Out log since there's no valid corresponding In log
					return prev;
				}
			}

			// Update the day's logs with the relevant log
			dayLogs[sitelog.type] = relevantLog;

			const inLog = dayLogs[SiteLogType.In]?.datetime?.toDate();
			const outLog = dayLogs[SiteLogType.Out]?.datetime?.toDate();

			const formattedLogsText = formatSiteLogs(inLog, outLog);

			return {
				...prev,
				[day]: {
					...dayLogs,
					formattedLogs: formattedLogsText,
				},
			};
		},
		createWeekWithDefault({
			[SiteLogType.In]: null,
			[SiteLogType.Out]: null,
			formattedLogs: null,
		}),
	);
};

export const intervalInHours = (
	start: Date | undefined,
	end: Date | undefined,
): number => {
	if (start === undefined || end === undefined) return 0;
	const duration = intervalToDuration({
		start,
		end,
	});
	const hours = duration.hours ?? 0;
	const minutesAsHours = (duration.minutes ?? 0) / 60;
	return hours + Math.round(minutesAsHours * 100) / 100;
};

export const calculateAutoCheck = (
	logs: TimesheetTableRowTimesheet['siteLogs'],
	activities: TimesheetTableActivity[],
	breakTime: number,
): boolean | null => {
	const { In, Out } = logs;
	const siteHours = intervalInHours(
		In?.datetime.toDate(),
		Out?.datetime.toDate(),
	);
	const timesheetHours = activities.reduce(
		(total, activity) => (total += activity.hours),
		0,
	);

	return siteHours === 0 && timesheetHours === 0
		? null
		: siteHours - breakTime >= timesheetHours;
};

export const comparerAutoCheckValue = (
	order: 'asc' | 'desc',
	value: TimesheetTableRowTimesheet['autoCheck'],
): 0 | 1 | 2 => {
	if (value === null) return 2; // always want at bottom
	if (order === 'asc') {
		return value ? 1 : 0;
	} else {
		return value ? 0 : 1;
	}
};

export const hoursOptions = (
	maxValue = 4,
	intervalMinutes: 3 | 6 | 12 | 15 | 30 = 15,
	minValue = 0,
): Record<number, string> => {
	const allOptions: Record<number, string> = {};
	let hours = minValue;
	const interval = intervalMinutes / 60;
	while (hours <= maxValue) {
		allOptions[hours] = getTimeForHours(hours);
		hours += interval;
	}
	return allOptions;
};

export const validateTimesheet = (
	activities: Record<
		keyof EditState['updatedActivities'],
		Pick<
			EditState['updatedActivities'][string],
			'id' | 'activity' | 'hours' | 'isNew'
		>
	>,
): Record<string, true> =>
	Object.values(activities).reduce<Record<string, true>>(
		(errors, activity) => {
			if (
				!isDeletedActivity(activity) &&
				(activity.activity === null ||
					activity.activity?.id === '' ||
					activity.activity?.name === '' ||
					activity.hours === 0)
			) {
				errors[activity.id] = true;
			}
			return errors;
		},
		{},
	);
