import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Stack,
} from '@mui/material';
import { useState } from 'react';
import { LeavePayHoursDropdown } from './LeavePayHoursDropdown';

export type LeavePayHoursDialogProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
	onSave: (value: number | null) => void;
	leaveHoursPerDay: number | null;
	options: Record<number, string>;
};

export const LeavePayHoursDialog = ({
	open,
	setOpen,
	onSave,
	leaveHoursPerDay,
	options,
}: LeavePayHoursDialogProps): JSX.Element => {
	const [saving, setSaving] = useState(false);
	const [hours, setHours] = useState<number | null | undefined>(undefined);

	const handleSave = async (): Promise<void> => {
		if (hours === undefined) return;
		setSaving(true);
		onSave(hours);
		setSaving(false);
		setHours(undefined);
	};

	const onClose = (): void => {
		setOpen(false);
		setHours(undefined);
	};

	return (
		<Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="xs">
			<DialogTitle>Leave Pay Hours</DialogTitle>
			<DialogContent>
				<Stack spacing={2} paddingY={1}>
					<DialogContentText fontSize="small">
						Set the hours per day used for leave requests.
					</DialogContentText>
					<LeavePayHoursDropdown
						hours={hours === undefined ? leaveHoursPerDay : hours}
						setHours={setHours}
						options={options}
						disabled={saving}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose} disabled={saving}>
					Cancel
				</Button>
				<LoadingButton
					variant="contained"
					disabled={saving}
					loading={saving}
					onClick={handleSave}
					sx={{ paddingX: 3 }}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
