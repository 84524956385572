import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { CustomSnackBar } from '../../../SnackBar/SnackBar';

export enum DetailsEventType {
	Delete = 'Delete',
	Save = 'Save',
	Notes = 'Notes',
	NotesFailed = 'NotesFailed',
	SaveWeekChanged = 'SaveWeekChanged',
	Empty = 'Empty',
	Invalid = 'Invalid',
	Failed = 'Failed',
	Success = 'Success',
	Downloading = 'Downloading',
}

export type EventType =
	| {
			eventType: Exclude<
				DetailsEventType,
				DetailsEventType.SaveWeekChanged
			>;
	  }
	| {
			eventType: DetailsEventType.SaveWeekChanged;
			timesheet: Timesheet;
	  };

type DetailsSnackbarProps = {
	event: EventType;
	navigateToTimesheet: (timesheet: Timesheet) => void;
	onClose: () => void;
};

export const DetailsSnackbar = ({
	event,
	navigateToTimesheet,
	onClose,
}: DetailsSnackbarProps): JSX.Element => {
	const [snackBarOpen, setSnackBarOpen] = useState(true);

	const handleSnackBarClose = (_?: unknown, reason?: string): void => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackBarOpen(false);
		onClose();
	};

	switch (event.eventType) {
		case DetailsEventType.Delete:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet Deleted"
				/>
			);
		case DetailsEventType.Save:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet Updated"
				/>
			);
		case DetailsEventType.SaveWeekChanged:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet Updated"
					autoHideDuration={12000}
					action={
						<>
							<Button
								size="small"
								color="inherit"
								variant="outlined"
								sx={{ marginRight: 0.5 }}
								onClick={(): void => {
									handleSnackBarClose();
									navigateToTimesheet(event.timesheet);
								}}>
								Go To Timesheet
							</Button>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={(): void => handleSnackBarClose()}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</>
					}
				/>
			);
		case DetailsEventType.Notes:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Notes Saved"
				/>
			);
		case DetailsEventType.NotesFailed:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Failed To Save All Notes"
					severity="error"
				/>
			);
		case DetailsEventType.Empty:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="No timesheet found"
					severity="error"
				/>
			);
		case DetailsEventType.Failed:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Could not download requested timesheet"
					severity="error"
				/>
			);
		case DetailsEventType.Invalid:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Incomplete activities"
					autoHideDuration={4000}
					severity="error"
				/>
			);
		case DetailsEventType.Success:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Timesheet exported and downloaded"
				/>
			);
		case DetailsEventType.Downloading:
			return (
				<CustomSnackBar
					open={snackBarOpen}
					onClose={handleSnackBarClose}
					snackBarText="Downloading your Timesheet. This may take 10-60 seconds"
					severity="info"
					loading={true}
				/>
			);
	}
};
