import cloudFunctionApi from '../../../../cloudfunctions';
import { accountTypes, UserProps } from '../../../../constants/Common';
import {
	Timesheet,
	isTempTimesheet,
} from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import type { FirebaseApi } from '../../../../firebase/firebaseApi';
import { downloadBlob } from '../../../helpers/fileDownloads';
import { useTimesheetSearchParams } from '../../hooks/TimesheetNavHooks';
import { useTimesheetsContext } from '../TimesheetsPageWrapper';
import { Details, DetailsFirebaseCalls } from './Details';

type AvailableFirebaseCalls = Extract<keyof FirebaseApi, DetailsFirebaseCalls>;

export const DetailsWrapper = ({
	userDetails,
	user,
}: UserProps): JSX.Element => {
	const {
		context: { timesheetID, timesheetData, firebaseApi, loading },
	} = useTimesheetsContext<AvailableFirebaseCalls>();

	const { setTimesheetSearchParams } = useTimesheetSearchParams();

	const currentTimesheet = timesheetData.find(
		(timesheet) =>
			timesheet.id === timesheetID && !isTempTimesheet(timesheet),
	);

	return (
		<Details
			currentTimesheet={
				currentTimesheet?.timesheetStatus &&
				currentTimesheet.timesheetStatus !== TimesheetStatus.Active
					? currentTimesheet
					: null
			}
			allTimesheets={timesheetData.filter(
				(timesheet): timesheet is Timesheet =>
					!isTempTimesheet(timesheet) &&
					timesheet.timesheetStatus !== TimesheetStatus.Active,
			)}
			setTimesheet={(value): void =>
				setTimesheetSearchParams({ timesheetID: value?.id })
			}
			canActionTimesheets={
				userDetails.accountType === accountTypes.management ||
				userDetails.accountType === accountTypes.seniorManagement ||
				(userDetails.accountType === accountTypes.handler &&
					userDetails.companyID ===
						currentTimesheet?.contractedTo?.id)
			}
			navigateWithoutRouter={(href): void => {
				// As we cannot change both timesheetID and weekEnding at the same time
				// navigate to page directly via the window.location interface
				window.location.href = href;
			}}
			firebaseApi={firebaseApi}
			userDetails={userDetails}
			user={user}
			cloudFunctionApi={cloudFunctionApi}
			loading={loading}
			downloadFile={downloadBlob}
		/>
	);
};
