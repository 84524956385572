import { Link, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import cloudFunctionApi from '../../../../cloudfunctions';
import { isRequiredLeaveMapped } from '../../../../constants/Leave';
import firebaseApi from '../../../../firebase/firebaseApi';
import {
	DEFAULT_ACTIVITY_KEY,
	PayrollType,
} from '../../../../models/Integrations/PayrollIntegration';
import { ActivityMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/ActivityMappingTab';
import { LeaveMappingTab } from '../../IntegrationPages/ActivityAndLeaveMapping/LeaveMappingTab';
import { BasePayrollPage } from '../../IntegrationPages/BaseIntegrationPage';
import { IntegrationTab } from '../../IntegrationPages/IntegrationTab';
import { PayrollEmployeesTab } from '../PayrollEmployeesTab';

const PAYHERO_INTEGRATIONS_LINK =
	'https://app.payhero.co.nz/#!/settings/integrations';

export const PayHeroPage = (): JSX.Element => {
	const navigate = useNavigate();
	const [value, setValue] = useState<string>('');
	const [token, setToken] = useState<string | null>(null);
	const [showError, setShowError] = useState(false);

	const tabs: IntegrationTab<PayrollType>[] = [
		{
			index: 0,
			name: 'Employees',
			content: (props) => (
				<PayrollEmployeesTab
					integrationName="PayHero"
					firebaseApi={firebaseApi}
					cloudFunctionApi={cloudFunctionApi}
					{...props}
				/>
			),
			optional: true,
			canMoveNext: () => true,
			fetchData: cloudFunctionApi.fetchPayrollEmployeeIDs,
		},
		{
			index: 1,
			name: 'Activities',
			content: (props) => (
				<ActivityMappingTab firebaseApi={firebaseApi} {...props} />
			),
			optional: false,
			canMoveNext: (payrollIntegration) =>
				DEFAULT_ACTIVITY_KEY in payrollIntegration.activityTypes,
			fetchData: cloudFunctionApi.fetchPayrollActivityIDs,
		},
		{
			index: 2,
			name: 'Leave',
			content: (props) => <LeaveMappingTab {...props} />,
			optional: false,
			canMoveNext: (payrollIntegration) =>
				isRequiredLeaveMapped(
					Object.keys(payrollIntegration.leaveTypes),
				),
			fetchData: cloudFunctionApi.fetchPayrollLeaveIDs,
		},
	];

	const handleAuthenticate = (): void => {
		if (value === '') {
			setShowError(true);
		} else {
			setToken(value);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (showError) setShowError(false);
		setValue(event.target.value);
	};

	const renderApiTextField = (): JSX.Element => (
		<Stack spacing={1}>
			<Typography>Input your companies PayHero Api Key below.</Typography>
			<TextField
				label="PayHero API Key"
				fullWidth
				value={value}
				onChange={handleChange}
				helperText={
					showError &&
					'API Key was not accepted. Please check your input'
				}
				error={showError}
			/>
			<Typography variant="caption">
				{'This key can be found at the bottom of your '}
				<Link
					href={PAYHERO_INTEGRATIONS_LINK}
					underline="hover"
					target="_blank"
					rel="noopener">
					PayHero Integrations page
				</Link>
				, under &quot;PayHero API&quot;.
			</Typography>
		</Stack>
	);

	const clearAll = (): void => {
		setToken(null);
		setValue('');
	};

	const handleError = (): void => {
		setShowError(true);
		setToken(null);
	};

	const handleCancel = useCallback(() => {
		clearAll();
		navigate('/connect', {
			state: {
				skipNavigate: true,
			},
		});
	}, [navigate]);

	return (
		<BasePayrollPage
			handleAuthenticate={handleAuthenticate}
			integrationType="PayHero"
			tabs={tabs}
			renderAuthenticationContent={renderApiTextField}
			token={token}
			handleSubmitError={handleError}
			handleSubmitSuccess={clearAll}
			handleCancelAuthenticate={handleCancel}
			fetchToken={cloudFunctionApi.fetchPayrollTokens}
			firebaseApi={firebaseApi}
			postEndIntegrationStep={handleCancel}
		/>
	);
};
