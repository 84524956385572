import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import { useEffect, useState } from 'react';
import cloudFunctionApi from '../../cloudfunctions';
import { Company, Site, UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { EditSiteDetails } from '../Management/Options/EditSite/EditSiteDetails';
import { NewSite } from '../Management/Options/NewSite/NewSite';
import { CustomSnackBar } from '../SnackBar/SnackBar';

export const ManageSites = ({ userDetails, user }: UserProps): JSX.Element => {
	const [filterBySiteName, setFilterBySiteName] = useState<string>('');
	const [filterByCompanyName, setFilterByCompanyName] = useState<string>('');
	const [sites, setSites] = useState<Site[]>([]);
	const [selected, setSelected] = useState(0);
	const [showCreateNewSite, setShowCreateNewSite] = useState(false);
	const [company, setCompany] = useState<Company>();
	const [companies, setCompanies] = useState<Record<string, Company>>();
	const [open, setOpen] = useState<boolean>(false);
	const [applyFilter, setApplyFilter] = useState(false);

	useEffect(() => {
		const fetchCompanies = async (): Promise<void> => {
			const allCompanies = await firebaseApi.getCompanies();
			setCompanies(allCompanies);
		};
		fetchCompanies();
	}, [userDetails.accountType]);

	useEffect(() => {
		const fetchSites = async (): Promise<void> => {
			let sites;
			if (filterBySiteName && filterByCompanyName) {
				sites = await firebaseApi.searchSitesByNameCompanyName(
					filterBySiteName,
					filterByCompanyName,
				);
			} else if (filterBySiteName) {
				sites = await firebaseApi.searchSitesByName(filterBySiteName);
			} else if (filterByCompanyName) {
				sites = await firebaseApi.searchSitesByCompanyName(
					filterByCompanyName,
				);
			}
			if (sites) {
				setSites(Object.values(sites));
			}
		};
		if (applyFilter) {
			fetchSites();
			setApplyFilter(false);
		}
	}, [applyFilter, filterBySiteName, filterByCompanyName]);

	const columns = [
		{
			name: 'name',
			label: 'Site Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'company',
			label: 'Company Name',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
		{
			name: 'status',
			label: 'Status',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: '25%',
					},
				}),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		selectableRows: 'single',
		tableBodyHeight: 'calc(100vh - 360px)',
		elevation: 2,
		viewColumns: false,
		selectableRowsOnClick: true,
		selectableRowsHideCheckboxes: true,
		selectToolbarPlacement: 'none',
		download: false,
		print: false,
	};

	const handleApplyFilters = (name: string | undefined = undefined): void => {
		const filterName = name ? name : filterBySiteName;
		if (filterName !== '' || filterByCompanyName !== '') {
			setFilterBySiteName(filterName);
			setApplyFilter(true);
		} else {
			setOpen(true);
		}
	};

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	): void => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const filtersValidation = (
		<CustomSnackBar
			open={open}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			severity="error"
			snackBarText="Please enter Site Name or Company Name"
		/>
	);

	const createNewSite = (
		<Dialog
			open={showCreateNewSite}
			fullWidth
			onClose={(): void => setShowCreateNewSite(false)}>
			<DialogTitle sx={{ margin: 0, padding: 2 }}>
				<IconButton
					aria-label="close"
					onClick={(): void => {
						setShowCreateNewSite(false);
						setCompany(undefined);
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography variant="h6" mb={2}>
					Select Company the New Site is for
				</Typography>
				{companies && (
					<TextField
						value={company?.id ?? ''}
						fullWidth
						label="Company (required)"
						onChange={(event): void => {
							setCompany(companies[event.target.value]);
						}}
						select>
						{Object.values(companies).map((company) => (
							<MenuItem key={company.id} value={company.id}>
								{company.name}
							</MenuItem>
						))}
					</TextField>
				)}
				{company && user && (
					<NewSite
						userDetails={userDetails}
						companyID={company.id}
						setShowCreateNewSite={setShowCreateNewSite}
						setFilterBySiteName={setFilterBySiteName}
						saveSiteCallback={handleApplyFilters}
						user={user}
						cloudFunctionApi={cloudFunctionApi}
						firebaseApi={firebaseApi}
					/>
				)}
			</DialogContent>
		</Dialog>
	);

	return (
		<>
			{filtersValidation}
			{createNewSite}
			<Paper id="filter" sx={{ mb: 1, padding: 1 }}>
				<Grid
					container
					spacing={1}
					alignItems="center"
					textAlign="center">
					<Grid item xs={12} sm={6}>
						<TextField
							value={filterBySiteName}
							fullWidth
							label="Site Name (case sensitive)"
							onChange={(event): void => {
								setFilterBySiteName(event.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="Company Name (case sensitive)"
							id="ComanyName"
							value={filterByCompanyName}
							onChange={(event): void =>
								setFilterByCompanyName(event.target.value)
							}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => handleApplyFilters()}>
							Apply filters
						</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="outlined"
							size="large"
							onClick={(): void => {
								setFilterByCompanyName('');
								setFilterBySiteName('');
								setSites([]);
							}}>
							Clear filters
						</Button>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							fullWidth
							variant="contained"
							size="large"
							onClick={(): void => {
								setShowCreateNewSite(true);
							}}>
							Add Site
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'column',
					lg: 'row',
				}}
				divider={
					<Divider
						sx={{ ml: 1, mr: 1 }}
						orientation="vertical"
						flexItem
					/>
				}
				spacing={2}>
				<Box flex="1">
					<DataTable
						tableData={sites}
						columns={columns}
						title="Sites in the system"
						selection={[selected, setSelected]}
						customTableOptions={tableOptions}
					/>
				</Box>
				<Box flex="1">
					{sites[selected] && user ? (
						<EditSiteDetails
							site={sites[selected]}
							allowArchiveSite
							allowEditSite
							saveSiteCallback={handleApplyFilters}
							user={user}
							cloudFunctionApi={cloudFunctionApi}
						/>
					) : (
						<Paper
							sx={{ padding: 1, height: 'calc(100vh - 245px)' }}>
							<Grid
								container
								height="100%"
								justifyContent="center"
								alignItems="center">
								<Grid item>
									<Typography textAlign="center" variant="h6">
										Search for a Site using the filters
										above and select one to show their
										details here.
									</Typography>
								</Grid>
							</Grid>
						</Paper>
					)}
				</Box>
			</Stack>
		</>
	);
};
