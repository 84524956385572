import type { User } from '../firebase/firebase';
import {
	defaultCloudFunctionServices,
	makeCloudFunctionRequestReturnSuccessStatus,
} from './BaseCloudFunctionRequests';
import { HTTPSCloudFunctionURLs } from './HTTPSCloudFunctionURLs';

export type EditNoteBody = {
	timesheetID: string;
	noteID: string;
	note: string;
};

export const editTimesheetNote = async (
	abortSignal: AbortSignal,
	user: User,
	timesheetID: string,
	noteID: string,
	note: string,
): Promise<boolean> => {
	return await makeCloudFunctionRequestReturnSuccessStatus<EditNoteBody>({
		abortSignal,
		user,
		url: HTTPSCloudFunctionURLs.EditTimesheetNote,
		method: 'POST',
		services: defaultCloudFunctionServices,
		body: {
			timesheetID,
			noteID,
			note,
		},
	});
};
